.Home .lander {
    padding-top: 40px;
    text-align: center;
}

.Home .lander h1 {
    font-weight: 600;
}

.Home{

}

.ScrollableExtended{
    overflow-y: scroll;
    height: 50vh;
}
.ScrollableExtendedShorter{
    overflow-y: scroll;
    height: 37vh;
}
.Scrollable{
    overflow-y: scroll;
    height: 20vh;
}