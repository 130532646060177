.Home .lander {
    padding-top: 40px;
    text-align: center;
}

.Home .lander h1 {
    font-weight: 600;
}

.Home{
}

.DisclamerFooter{
    margin-right: 2rem;
    margin-left: 2rem;
}

.PanelFix{
    min-width: auto;
}